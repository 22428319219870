/************************************************************************************************
VANILLA JS/CSS SIDEBAR
*************************************************************************************************/

.sidebar-temp-overlay{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1100;

  .mask {
    position:absolute;
    top:0; /* Add a top and bottom position so the element */
    bottom:0; /* stretches to the height of the parent */
    left:0;
    width:100%;
    background-color:#000;
    opacity: 0.8;
    -moz-opacity: 0.8;
    filter:alpha(opacity=80);
  }

  &.opened{
    display: block;
  }

  &.closed{
    display: none;
  }

  .tempSidebarCloseBtn{
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  .sidebar-temp{
    height: 100%;
    background-color: white;
    width: 800px;
    max-width: 800px;
    z-index: 1200;
    position: absolute;
    right: 0;
  }
}



